<template>
  <b-container fluid>
    <header-logo
      :chargemondoOpp="isChargemondoOpp"
      :baywaOpp="isBaywaOpp"
      :baywaOppUnique="isBaywaOppUnique"
      :isLoading="loading"
    />
    <div v-if="order">
      <b-row class="border-bottom">
        <b-col cols="12" lg="8" offset-lg="4">
          <i18n
            path="order-details.title"
            tag="h1"
            class="h2 font-weight-light mt-3 mb-4"
          >
            <strong slot="job">{{ order.job.name }}</strong>
            <strong slot="opportunity">{{ order.opportunity.name }}</strong>
            <strong slot="brand">{{ order.brand.name }}</strong>
          </i18n>
        </b-col>
      </b-row>

      <!-- Summary -->

      <b-row class="border-bottom py-3" v-if="order.job.date_expected">
        <b-col cols="12" lg="4" class="text-muted">
          {{ $t('inputs.headings.delivery_date') }}:
        </b-col>
        <b-col cols="12" lg="8">
          {{ order.job.date_expected | moment('L') }}
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3" v-if="order.job.readiness_date">
        <b-col cols="12" lg="4" class="text-muted">
          {{ $t('inputs.headings.readiness_date') }}:
        </b-col>
        <b-col cols="12" lg="8">
          {{ order.job.readiness_date | moment('L') }}
        </b-col>
      </b-row>
      <b-row
        class="border-bottom py-3"
        v-if="order.job.appointment_start_timestamp"
      >
        <b-col cols="12" lg="4" class="text-muted">
          {{ $t('inputs.headings.appointment_date') }}:
        </b-col>
        <b-col cols="12" lg="8">
          <strong>
            {{ order.job.appointment_start_timestamp | moment('L') }},
            {{ order.job.appointment_start_timestamp | moment('H:mm:ss') }} -
            {{ order.job.appointment_end_timestamp | moment('H:mm:ss') }}
          </strong>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3" v-else-if="order.job.dates.length > 0">
        <b-col cols="12" lg="4" class="text-muted">
          {{ $t('inputs.headings.availability_dates') }}:
        </b-col>
        <b-col cols="12" lg="8">
          <ul class="mb-0">
            <li v-for="(date, index) in order.job.dates" :key="index">
              <strong>{{ date.start | moment(' dd, L ') }}</strong>
              <span>
                {{ date.start | moment('LT') }} -
                {{ date.end | moment('LT') }}
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row
        class="border-bottom py-3"
        v-if="
          orderItems.length && order.job.status != 'on hold before accepted'
        "
      >
        <b-table-simple
          v-e2e:customerServiceTable
          stacked="md"
          striped
          class="mb-0"
        >
          <b-thead>
            <b-tr>
              <b-th>
                {{ $t('order-items.name') }}
              </b-th>
              <b-th class="text-right">
                {{ $t('order-items.quantity') }}
              </b-th>
              <template v-if="!customerSignatureStatusShown">
                <b-th class="text-right">
                  {{ $t('order-items.unit_price') }}
                </b-th>
                <b-th class="text-right">
                  {{ $t('order-items.total_amount') }}
                </b-th>
              </template>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in orderItems" :key="item.id">
              <b-th :stacked-heading="$t('order-items.name')">
                <span>{{ item.name }}</span>
                <ul class="font-weight-normal" v-if="item.details">
                  <li
                    v-for="(detail, index) in item.details"
                    :key="`detail-${index}`"
                  >
                    {{ detail.label }}: {{ detail.value }}
                  </li>
                </ul>
              </b-th>
              <b-td
                class="text-right"
                :stacked-heading="$t('order-items.quantity')"
              >
                <span>{{ item.quantity }}</span>
              </b-td>

              <template v-if="!customerSignatureStatusShown">
                <b-td
                  class="text-right"
                  :stacked-heading="$t('order-items.unit_price')"
                >
                  <span>{{ formattedPrice(item.priceLists) }}</span>
                </b-td>

                <b-td
                  class="text-right"
                  :stacked-heading="$t('order-items.total_amount')"
                >
                  <span v-if="item.quantity * item.priceLists">
                    {{ formattedPrice(item.quantity * item.priceLists) }}
                  </span>
                </b-td>
              </template>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
      <b-row class="border-bottom py-3">
        <b-col cols="12" lg="4" class="text-muted mb-2">
          {{ $t('job-status') }}:
        </b-col>
        <b-col cols="12" lg="8">
          <h5>
            {{ order.status_description.customer_name[locale] }}
          </h5>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3" v-if="onHoldReasons.length > 0">
        <b-col cols="12" lg="4" class="text-muted mb-2">
          {{ $t('on-hold-reasons') }}:
        </b-col>
        <b-col cols="12" lg="8">
          <div
            v-for="(reason, index) in onHoldReasons"
            :key="`reason-${index}`"
          >
            <h5>
              <b-badge variant="warning">
                {{ $t(`state.${reason.state}`) }}
              </b-badge>
            </h5>
            <template v-if="reason.event">
              -&gt;
              <b-button
                size="sm"
                variant="primary"
                @click="prepareEventChange(reason.event)"
              >
                {{ $t(`state-events.${reason.event}`) }}
              </b-button>
            </template>
            <template
              v-else-if="checkProductOrderToValidateState(reason.state)"
            >
              <div
                v-for="(po, index) in productOrdersToConfirmDelivery"
                :key="index"
              >
                <div
                  v-for="product in po.items"
                  :key="product.id"
                  :title="product.name"
                >
                  <strong>{{ product.quantity }} x {{ product.name }}</strong>
                </div>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="confirmProductOrder(po)"
                >
                  {{ $t(`product-order-confirm.action`) }}
                </b-button>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>

      <ProductOrderConfirm
        ref="product-order-confirm"
        :jobId="order.job.api_id"
        @confirmed="confirmedProductOrder"
      />

      <!-- Actions -->
      <template v-if="!hideActionsBlock">
        <b-row v-if="!change" class="border-bottom py-3 py-lg-5">
          <b-col cols="12" lg="4" class="text-muted">
            <h3 class="font-weight-light mb-3 mb-lg-0 mt-2">
              {{ $t('order-details.actions') }}:
            </h3>
          </b-col>
          <b-col cols="12" lg="8">
            <div
              class="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center"
            >
              <b-button
                v-for="(action, index) in options.statuses"
                :key="index"
                @click="prepareChange({ status: action.name })"
                variant="primary"
                size="lg"
                class="text-uppercase px-5 mr-sm-2 mb-2 mb-sm-0 d-block d-sm-inline-block"
              >
                {{ action.label }}
              </b-button>
              <span
                class="mr-sm-2 mb-2 mb-sm-0 text-center"
                v-if="options.statuses.length > 0"
              >
                {{ $t('or') }}
              </span>
              <b-dropdown
                v-if="options.cancellations.length > 0"
                right
                size="lg"
                :text="$t('cancel-reason')"
                boundary="viewport"
                class="gid-cr-select"
              >
                <b-dropdown-item
                  v-for="cr in options.cancellations"
                  :key="cr.id"
                  @click="prepareChange({ cancellation: cr.id })"
                >
                  {{ cr.description[locale] }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <p class="text-muted m-0 mt-3">
              {{ $t('order-details.cancellation-reasons-intro') }}
            </p>
          </b-col>
        </b-row>
        <template v-else-if="change.loading && change.loading.form">
          <b-row class="border-bottom py-3">
            <b-col cols="12" lg="8" offset-lg="4">
              <b-spinner variant="secondary"></b-spinner>
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <b-row
            v-if="options.cancellations.length > 0"
            class="border-bottom py-3"
          >
            <b-col cols="12" lg="8" offset-lg="4">
              <div
                class="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center"
              >
                <h2
                  v-if="Boolean(change.title)"
                  class="font-weight-light mr-sm-2"
                >
                  {{ change.title }}
                </h2>
                <div v-if="change.status && !change.showCancel">
                  <b-dropdown
                    :text="$t('cancel-reason')"
                    boundary="viewport"
                    class="gid-cr-select"
                  >
                    <b-dropdown-item
                      v-for="cr in options.cancellations"
                      :key="cr.id"
                      @click="prepareChange({ cancellation: cr.id })"
                    >
                      {{ cr.description[locale] }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <p class="text-muted m-0 mt-3" v-if="change.status">
                {{ $t('order-details.cancellation-reasons-intro') }}
              </p>
            </b-col>
          </b-row>

          <b-row
            class="border-bottom py-3"
            v-if="
              change.cancellation &&
              ((change.cancellation.instructions &&
                change.cancellation.instructions[locale]) ||
                (change.description && change.description[locale]))
            "
          >
            <b-col cols="12" lg="8" offset-lg="4">
              <h5
                class="font-weight-light"
                v-if="
                  change.cancellation.instructions &&
                  change.cancellation.instructions[locale]
                "
              >
                {{ change.cancellation.instructions[locale] }}
              </h5>
              <h5
                class="font-weight-light"
                v-if="change.description && change.description[locale]"
              >
                {{ change.description[locale] }}
              </h5>
            </b-col>
          </b-row>

          <div>
            <div>
              <template v-for="input in change.inputs">
                <component
                  :key="input.name"
                  v-if="input.name === 'order-intake-protocol'"
                  :is="input.component"
                  :type="input.type"
                  :input-name="input.name"
                  v-bind.sync="input.extra"
                  @fetched="orderIntakeFormsFetched"
                  @redirect="orderIntakeFormsRedirect"
                />
                <input-holder
                  v-else
                  :key="`else-${input.name}`"
                  :input-name="input.name"
                >
                  <component
                    :is="input.component"
                    :type="input.type"
                    :input-name="input.name"
                    v-bind.sync="input.extra"
                  />
                  <component :is="input.detailsComponent" :job-view="order" />
                </input-holder>
              </template>
            </div>
            <b-row
              class="bg-light border-bottom py-3"
              v-if="change.description"
            >
              <b-col cols="12" lg="8" offset-lg="4">
                {{ change.description }}
              </b-col>
            </b-row>
            <b-row class="border-bottom py-3" v-if="!change.disabled">
              <!-- /.border-bottom -->
              <b-col cols="12" lg="8" offset-lg="4">
                <b-button
                  @click="confirmChange"
                  :disabled="
                    change.disabled || (change.loading && change.loading.button)
                  "
                  variant="primary"
                  size="lg"
                  class="text-uppercase px-5 mr-sm-2 mb-2 mb-sm-0 d-block d-sm-inline-block"
                >
                  <b-spinner
                    v-if="change.loading && change.loading.button"
                    class="gid-spinner--button mr-2 ml-n2"
                  ></b-spinner>
                  {{ change.confirmLabel }}
                </b-button>
                <b-button
                  v-if="change.showCancel"
                  @click="cancelChange"
                  size="lg"
                  class="mr-sm-2 mb-2 mb-sm-0 d-block d-sm-inline-block"
                >
                  {{ $t('cancel') }}
                </b-button>
              </b-col>
            </b-row>
            <!-- /.border-bottom -->
          </div>
        </template>
        <b-row v-if="changeResult" class="border-bottom py-3">
          <b-col cols="12" lg="8" offset-lg="4">
            <b-alert show :variant="changeResult.type" class="mb-2">
              <h3 class="alert-heading">{{ changeResult.title }}</h3>
              <p v-if="changeResult.description">
                {{ changeResult.description }}
              </p>
              <p v-if="changeResult.type == 'danger'">
                <em>{{ $t('_errors.report-explain') }}</em>
              </p>
              <b-card v-if="changeResult.validationErrors" class="text-body">
                <div
                  v-for="(input, inputName) in changeResult.validationErrors"
                  :key="inputName"
                >
                  <h4>{{ $t(`inputs.headings.${inputName}`) }}</h4>
                  <ul class="mb-0">
                    <li v-for="(error, index) in input" :key="index">
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </b-card>
              <div v-if="changeResult.details">
                <b-collapse id="result-details" class="my-2">
                  <b-card>
                    <small>
                      <pre>{{ changeResult.details }}</pre>
                    </small>
                  </b-card>
                </b-collapse>
                <b-btn v-b-toggle.result-details class="mt-2">
                  {{ $t('_errors.buttons.error-details') }}
                </b-btn>
              </div>
            </b-alert>
          </b-col>
        </b-row>
      </template>
      <input-holder
        input-name="customer-signature"
        v-if="customerSignatureStatusShown"
      >
        <customer-signature-status
          :order="order"
          @signatureDeclined="onSignatureDecline"
        />
      </input-holder>
    </div>
    <not-found v-else-if="order === false" />
    <b-spinner v-else variant="secondary" class="my-3"></b-spinner>
  </b-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';
import InputHolder from './InputHolder.vue';
import NotFound from '@/views/NotFound.vue';
import AppointmentsList from '@gid/vue-common/components/inputs/AppointmentsList.vue';
import DeliveryDate from '@gid/vue-common/components/inputs/DeliveryDate.vue';
import ReadinessDate from '@gid/vue-common/components/inputs/ReadinessDate.vue';
import CustomerNeeds from '@gid/vue-common/components/CustomerNeeds.vue';
import HeaderLogo from '@/components/HeaderLogo.vue';
import OrderIntakeForms from '@gid/vue-common/components/OrderIntakeForms';
import CustomerSignatureStatus from '@/components/CustomerSignatureStatus';
import ProductOrderConfirm from '../components/ProductOrderConfirm.vue';

import { JOB_INIT_INPUTS } from '@gid/vue-common/store/jobs.module';

import {
  AVAILABILITY_DATES,
  DELIVERY_DATE,
  READINESS_DATE,
} from '@gid/vue-common/components/inputs/_types';

import {
  CONTEXT_CREATE,
  CONTEXT_DESTROY,
  STORE_API_ERRORS,
} from '@gid/vue-common/store/validation.module/types';
import { filterNonInputErrors } from '@gid/vue-common/store/validation.module/utils';
import { excludePrepaymentServices } from '@gid/vue-common/utils';
import { JobStatusEnum, FormOrderModificationCategoryEnum } from '@gid/models';
import { StatusRulesNamesEnum } from '@gid/models/dist/entities/status-rules-names-enum';
import Quote, {
  CHARGEMONDO_OPP,
  BAYWA_DIENSTWAGENFAHRER_OPP,
  BAYWA_MEHRPLATZ_OPP,
} from './Quote.vue';
import { CondOperator, RequestQueryBuilder } from '@dataui/crud-request';

const stateToEventMap = {
  [`${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.CUSTOMER_PREWORK_BEFORE_ACCEPTED}.waiting`]: `CUSTOMER_PREWORK_BEFORE_ACCEPTED_DONE`,
  [`${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_NOT_REACHABLE}.unreachable`]: `CUSTOMER_REACHED`,
  [`${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_WAITING_PRODUCT}.awaiting`]: `CUSTOMER_WAITING_PRODUCT_DONE`,
  [`${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_WAITING_SUBSIDY}.awaiting`]: `CUSTOMER_WAITING_SUBSIDY_DONE`,
  [`${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_PREWORK}.awaiting`]: `CUSTOMER_PREWORK_DONE`,
  [`${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_PAUSE_GENERIC}.paused`]: `CUSTOMER_UNPAUSE`,
  [`${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.BRAND_PRODUCT_ORDERING}.waiting`]: `BRAND_PRODUCT_ORDERING_DONE`,
};
const PRODUCT_ORDER_TO_CONFIRM_STATUS = `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PRODUCT_ORDERING}.pending-delivery`;

const inputsOrder = [DELIVERY_DATE, READINESS_DATE, AVAILABILITY_DATES];
const VALIDATION_CONTEXT_PREFIX = 'order';
const VODAFONE_OPPORTUNITY_SENDERNEUSORTIERUNG = '0060800000tKs8EAAS';

const NO_ACTION_STATUSES = [
  JobStatusEnum.CANCELLED,
  JobStatusEnum.COMPLETED,
  JobStatusEnum.STARTED,
  JobStatusEnum.FINISHED,
  JobStatusEnum.INVOICE_SENT,
  JobStatusEnum.APPROVAL_PENDING,
  JobStatusEnum.CLOSED,
];

export default {
  components: {
    InputHolder,
    HeaderLogo,
    OrderIntakeForms,
    CustomerSignatureStatus,
    Quote,
    NotFound,
    CustomerNeeds,
    ProductOrderConfirm,
    'input-availability_dates': AppointmentsList,
    'input-delivery_date': DeliveryDate,
    'input-readiness_date': ReadinessDate,
  },
  props: {
    id: String,
  },
  data() {
    return {
      order: null,
      loading: true,
      options: {
        statuses: [],
        cancellations: [],
        jobServiceEvents: [],
        productOrders: [],
      },
      requestMixin: {},
      change: null,
      changeResult: null,
    };
  },
  computed: {
    ...mapGetters(['locale', 'access_token']),
    customerSignatureStatusShown() {
      return (
        this.order &&
        !this.order.is_remote &&
        ([
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.COMPLETED,
          JobStatusEnum.INVOICE_SENT,
        ].includes(this.order.job.status) ||
          (this.order.job.status === JobStatusEnum.ON_HOLD &&
            this.order.job.states?.list?.some((state) =>
              [
                `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_SIGNATURE}.missing-customer`,
                `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_SIGNATURE}.modification`,
              ].includes(state),
            )))
      );
    },
    orderItems() {
      if (!this.order) return null;
      const products = this.order.products.map((product) => {
        const details = [];
        if (product.serial_number) {
          details.push({
            label: this.$t('serial'),
            value: product.serial_number,
          });
        }
        if (product.chargepoint_number) {
          details.push({
            label: 'Chargepoint',
            value: product.chargepoint_number,
          });
        }
        return {
          ...product,
          details,
        };
      });
      const items = [
        ...this.order.services,
        ...products,
        ...this.order.custom_services.filter(excludePrepaymentServices),
        ...this.order.additional_items,
      ]
        .map((item) => ({
          name: item.name,
          priceLists: item.price_lists ? item.price_lists : item.price_listing,
          pricePurchasing: item.price_purchasing_net
            ? item.price_purchasing_net
            : item.price_purchasing,
          priceSelling: item.price_selling
            ? item.price_selling
            : item.price_selling_net,
          quantity: item.quantity,
          id: item.api_id ? item.api_id : item.id,
          details: item.details?.length ? item.details : undefined,
        }))
        .filter((item) => Boolean(item.priceLists));
      return items;
    },
    sendInputs() {
      return this.$store.getters.jobInputsAllValues(this.order.job.sfid);
    },
    validationContext() {
      return `${VALIDATION_CONTEXT_PREFIX}-${this.order.job.sfid}`;
    },
    hideActionsBlock() {
      return NO_ACTION_STATUSES.includes(
        this.order?.status_description?.job_status,
      );
    },
    onHoldReasons() {
      return (
        this.order.job.states?.list?.[0]?.startsWith(this.order.job.status) &&
        this.order.job.states?.list?.[0].includes('.')
          ? this.order.job.states?.list
          : []
      ).map((state) => {
        const event = stateToEventMap[state];
        return this.options.jobServiceEvents.includes(event)
          ? { state, event }
          : { state };
      });
    },
    productOrdersToConfirmDelivery() {
      return this.options.productOrders
        .filter(({ trackingID }) => !trackingID)
        .map((productOrder) => ({
          ...productOrder,
          items: productOrder.items.map((item) =>
            this.order.products.find(
              (product) => product.id == item.product.id,
            ),
          ),
        }));
    },
    isChargemondoOpp() {
      return this.order?.opportunity.sfid == CHARGEMONDO_OPP;
    },
    isBaywaOpp() {
      return BAYWA_DIENSTWAGENFAHRER_OPP == this.order?.opportunity.sfid;
    },
    isBaywaOppUnique() {
      return BAYWA_MEHRPLATZ_OPP == this.order?.opportunity.sfid;
    },
  },
  watch: {
    id() {
      this.loadData();
    },
    order(newValue, oldValue) {
      if (oldValue) {
        this.destroyValidationContext(oldValue.job.sfid);
      }
      if (newValue) {
        this.fetchAvailableActions();
        this.createValidationContext(newValue.job.sfid);
      }
    },
  },
  created() {
    this.loadData();
  },
  destroyed() {
    if (this.order) {
      this.destroyValidationContext(this.order.job.sfid);
    }
  },
  methods: {
    loadData() {
      axios
        .get(`/api/customer/orders/${this.id}`)
        .then((response) => {
          this.order = response.data;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            this.order = false;
          }
        });
    },
    formattedPrice(price) {
      const locale = this.order.customer.language;
      const { country } = this.order.customer.shipping_address;
      return new Intl.NumberFormat(`${locale}-${country}`, {
        style: 'currency',
        currency: 'EUR',
      }).format(price);
    },
    fetchAvailableActions() {
      axios
        .post(`/api/customer/orders/set-status`, {
          preview: true,
          job_id: this.order.job.sfid,
        })
        .then((response) => {
          this.options.statuses = response.data.statuses
            .filter((status) => {
              return status.allowed;
            })
            .map((status) => ({
              name: status.job_status,
              label: status.customer_name[this.locale],
            }));
          this.tryAutoStatusAction();
        });

      axios
        .get(`/api/customer/orders/${this.order.job.sfid}/cancellation-reasons`)
        .then((response) => {
          this.options.cancellations = response.data;
        })
        .catch((error) => {
          this.options.cancellations = error;
        });

      axios
        .get(
          `/jobs-api/status-rules/job/${this.order.job.api_id}/events-available`,
        )
        .then(({ data }) => {
          this.options.jobServiceEvents = data;
        });

      const poQuery = RequestQueryBuilder.create({
        search: {
          'job.id': { [CondOperator.IN]: [this.order.job.sfid] },
        },
      }).query();

      axios.get(`/documents-api/product-orders?${poQuery}`).then(({ data }) => {
        this.options.productOrders = data;
      });
    },
    checkProductOrderToValidateState(state) {
      return state === PRODUCT_ORDER_TO_CONFIRM_STATUS;
    },
    tryAutoStatusAction() {
      if (this.options.statuses.length == 1) {
        this.prepareChange({ status: this.options.statuses[0].name });
      }
    },
    prepareChange({ status, cancellation }) {
      let changeMixin;
      if (cancellation) {
        let cr = this.options.cancellations.find((cr) => cr.id == cancellation);
        changeMixin = {
          title: cr.description[this.locale],
          confirmLabel: this.$t('confirm'),
          showCancel: true,
          cancellation: cr,
        };
        this.requestMixin = {
          cancellation_reason_id: cancellation,
        };
      } else if (status) {
        changeMixin = {
          status: status,
          confirmLabel: this.$t('confirm'),
          showCancel: this.options.statuses.length > 1,
        };
        this.requestMixin = {
          new_status: status,
        };
      }

      this.changeResult = null;
      this.change = { loading: { form: true } };

      axios
        .post(`/api/customer/orders/set-status`, {
          preview: true,
          job_id: this.order.job.sfid,
          ...this.requestMixin,
        })
        .then(async (response) => {
          const inputs = await this.initInputs(response.data.inputs);
          this.change = {
            inputs,
            ...changeMixin,
          };
          this.change.disabled =
            (!cancellation && this.change.inputs.length === 0) ||
            this.change.inputs.findIndex(
              (x) => x.name === 'order-intake-protocol',
            ) !== -1;
          if (
            response.data.price_changes &&
            response.data.price_changes &&
            this.order.job.price_lists -
              response.data.price_changes.price_lists_netto >=
              0.01
          ) {
            this.change.description = response.data.message[this.locale];
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.change = null;
        });
    },
    prepareEventChange(event) {
      this.changeResult = null;
      this.change = {
        title: this.$t(`state-events.${event}`),
        // description: `Are you sure you want to apply ${event}?`,
        confirmLabel: this.$t(`state-events.${event}`),
        showCancel: true,
        event,
      };
      this.confirmChange();
    },
    orderIntakeFormsFetched(data) {
      const inputIndex = this.change.inputs.findIndex(
        (x) => x.name === 'order-intake-protocol',
      );
      if (inputIndex !== -1) {
        this.change.inputs[inputIndex].extra.fetched = data;
      }
    },
    orderIntakeFormsRedirect(data) {
      this.$router.push({
        name: 'order-success',
        params: { id: this.id },
        query: {
          title: this.$t('customer-signature-action.taken.title'),
          description: this.$t('customer-signature-action.taken.description'),
        },
      });
    },
    async assignComponentOutputDataFromProtocolIntakeForm(
      componentOutput,
      formType,
      protocolType,
    ) {
      const customerFormUrl = `/order-intake-forms-api/form/${this.order.opportunity.sfid}/form-type/${formType}/protocol-type/${protocolType}/category/${FormOrderModificationCategoryEnum.CONFIRMATION}`;
      let customerForm = null;
      try {
        const customerFormResponse = await axios.get(customerFormUrl);
        customerForm = customerFormResponse.data;
        if (customerForm) {
          componentOutput.extra.locale = customerForm.locale;
          componentOutput.extra.form = customerForm.id;
          componentOutput.extra.theme = customerForm.theme;
        }
      } catch (err) {
        console.error(err);
      }
      return componentOutput;
    },
    buildProtocolIntakeComponentOutput() {
      let extra = {
        elementId: this.order.job.sfid,
        jobId: this.order.job.sfid,
        validationContext: this.validationContext,
        validationPath: 'order-intake-forms',
        token: this.order.opportunity.brand_id,
        opportunitySfid: this.order.opportunity.sfid,
        orderApiId: this.order.api_id,
        accessToken: this.access_token,
      };

      const componentOutput = {
        name: 'order-intake-protocol',
        type: 'required',
        component: 'order-intake-forms',
        data: {},
        extra,
      };

      return componentOutput;
    },
    async invokeProtocolIntake(_formType, inputsArray) {
      if (
        this.order.job.status === JobStatusEnum.ON_HOLD &&
        this.order.job.states?.list?.some((state) =>
          [
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_SIGNATURE}.missing-customer`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_SIGNATURE}.modification`,
          ].includes(state),
        )
      ) {
        const formType = _formType;
        let protocolType = 'default';

        if (formType === 'installation') {
          if (
            this.order.opportunity.sfid ===
            VODAFONE_OPPORTUNITY_SENDERNEUSORTIERUNG
          ) {
            protocolType = 'incident';
          }
        }

        let componentOutputDefinition =
          this.buildProtocolIntakeComponentOutput();
        let componentOutput =
          await this.assignComponentOutputDataFromProtocolIntakeForm(
            componentOutputDefinition,
            formType,
            protocolType,
          );
        if (componentOutput.extra.form) {
          inputsArray.push(componentOutput);
        }
      }
    },
    async initInputs(inputs) {
      let inputsArray = [];
      let minDate = this.order.job.readiness_date
        ? moment(this.order.job.readiness_date)
        : this.order.job.date_expected
        ? moment(this.order.job.date_expected)
        : moment(this.order.job.created_date)
            .startOf('day')
            .add(this.order.opportunity.assembly_time_days || 0, 'days')
            .add(this.order.opportunity.default_days_for_delivery || 0, 'days');
      if (minDate.isBefore(moment().startOf('day'))) {
        minDate = moment().startOf('day');
      }

      if (this.order.is_precheck) {
        await this.invokeProtocolIntake('precheck', inputsArray);
      } else if (this.order.is_installation) {
        await this.invokeProtocolIntake('installation', inputsArray);
      } else if (this.order.is_meter_application) {
        await this.invokeProtocolIntake('meter_application', inputsArray);
      } else if (this.order.is_installation_preparation) {
        await this.invokeProtocolIntake(
          'installation_preparation',
          inputsArray,
        );
      } else if (this.order.is_maintenance) {
        await this.invokeProtocolIntake('maintenance', inputsArray);
      } else if (this.order.is_defect_elimination) {
        await this.invokeProtocolIntake('defect_elimination', inputsArray);
      }

      for (const inputName in inputs) {
        const inputType = inputs[inputName].state;
        let component = `input-${inputName}`;
        let detailsComponent;
        let initData = null;
        let extra = {
          jobId: this.order.job.sfid,
          validationContext: this.validationContext,
          validationPath: inputName,
        };

        if (!['required', 'optional', 'already_set'].includes(inputType)) {
          continue;
        }

        if (inputName == DELIVERY_DATE) {
          initData = this.order.job.date_expected;
          extra.hint = this.$t('inputs.delivery_date.hint');
        } else if (inputName == READINESS_DATE) {
          initData = this.order.job.readiness_date;
          extra.hint = this.$t('inputs.readiness_date.hint');
          extra.onlyAfterDate = moment().startOf('day');
          detailsComponent = CustomerNeeds;
        } else if (inputName == AVAILABILITY_DATES) {
          initData = this.order.job.dates;
          extra.multiday = this.order.opportunity.multiday;
        } else {
          continue;
        }

        inputsArray.push({
          name: inputName,
          type: inputType,
          data: initData,
          extra,
          component,
          detailsComponent,
        });
      }

      inputsArray.sort(
        (a, b) => inputsOrder.indexOf(a.name) - inputsOrder.indexOf(b.name),
      );
      this.$store.dispatch(JOB_INIT_INPUTS, {
        jobId: this.order.job.sfid,
        inputs: inputsArray,
        constrains: {
          minDate,
        },
      });

      return inputsArray;
    },
    confirmChange() {
      this.change = {
        ...this.change,
        loading: { button: true },
      };
      this.changeResult = null;

      if (this.change.event) {
        const stateEvent = this.change.event;
        axios
          .post(
            `/jobs-api/status-rules/job/${this.order.job.api_id}/apply-event`,
            {
              type: stateEvent,
            },
          )
          .then(() => {
            this.changeResult = {
              type: 'success',
              title: this.$t('successful'),
            };
            this.change = null;
            this.loadData();
          });
      } else {
        axios
          .post(`/api/customer/orders/set-status`, {
            preview: false,
            job_id: this.order.job.sfid,
            ...this.requestMixin,
            inputs: this.sendInputs,
          })
          .then((response) => {
            this.order = response.data;
            this.changeResult = {
              type: 'success',
              title: this.$t('successful'),
            };
            this.change = null;
          })
          .catch(this.handleError);
      }
    },
    handleError(error) {
      if (error.response) {
        if (error.response.status == 422) {
          const nonInputErrors = filterNonInputErrors(
            error.response.data.errors,
            Object.keys(this.sendInputs),
          );
          if (Object.keys(nonInputErrors).length > 0) {
            this.changeResult = {
              type: 'danger',
              title: this.$t('_errors.server.title'),
              description: this.$t('_errors.server.details'),
              validationErrors: nonInputErrors,
            };
          }
          this.$store.dispatch(`validation/${STORE_API_ERRORS}`, {
            context: this.validationContext,
            errors: error.response.data.errors,
          });
        } else {
          this.changeResult = {
            type: 'danger',
            title:
              this.$t('_errors.server.title') + ` - ${error.response.status}`,
            description: this.$t('_errors.server.details'),
          };
        }
      } else if (error.request) {
        this.changeResult = {
          type: 'danger',
          title: this.$t('_errors.network.title'),
          description: this.$t('_errors.network.details'),
        };
      }
      this.change.loading = false;
    },
    cancelChange() {
      this.change = null;
      this.changeResult = null;
      this.tryAutoStatusAction();
    },
    createValidationContext(context) {
      this.$store.dispatch(`validation/${CONTEXT_CREATE}`, {
        context: `${VALIDATION_CONTEXT_PREFIX}-${context}`,
      });
    },
    destroyValidationContext(context) {
      this.$store.dispatch(`validation/${CONTEXT_DESTROY}`, {
        context: `${VALIDATION_CONTEXT_PREFIX}-${context}`,
      });
    },
    onSignatureDecline(order) {
      this.$router.push({
        name: 'order-success',
        params: { id: this.id },
        query: {
          title: this.$t('customer-signature-action.declined.title'),
          description: this.$t(
            'customer-signature-action.declined.description',
          ),
        },
      });
    },
    confirmProductOrder(productOrder) {
      this.$refs['product-order-confirm'].show(productOrder);
    },
    confirmedProductOrder() {
      // ugly, but best I could think of for now:
      // delay loading the job to give some time for async backend
      // actions to change the job's state
      this.change = { loading: { form: true } };
      setTimeout(() => {
        this.change = false;
        this.options.statuses = [];
        this.options.cancellations = [];
        this.options.jobServiceEvents = [];
        this.options.productOrders = [];
        this.loadData();
      }, 3000);
    },
  },
};
</script>
